@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  letter-spacing: .025em;
  user-select: none;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@font-face {
  font-family: 'Lilita';
  src: url('./assets/fonts/lilita.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gaegu';
  src: url('./assets/fonts/gaegu-400.woff2') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gaegu';
  src: url('./assets/fonts/gaegu-700.woff2') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

#header-nav {
  animation: blur linear both;
  animation-timeline: scroll();
  animation-range: 100px 200px;
}

@keyframes blur {
  to {
    backdrop-filter: blur(10px);
    padding: 0.5rem 1rem;
    margin: 0;
  }
}